<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        md="8"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filterHistorySearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistorySearch"
            v-model="tableHistories.filter.search"
            placeholder="search here"
            autocomplete="off"
            debounce="1000"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
      >
        <b-form-group
          label="Received From"
          label-for="filterHistoryDateFrom"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistoryDateFrom"
            v-model="tableHistories.filter.date_from"
            :max="tableHistories.filter.date_to"
            debounce="1000"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
      >
        <b-form-group
          label="Received To"
          label-for="filterHistoryDateTo"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistoryDateTo"
            v-model="tableHistories.filter.date_to"
            :min="tableHistories.filter.date_from"
            debounce="1000"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="px-1 mb-2 d-flex align-items-end pb-1"
      >
        <b-overlay
          rounded
          opacity="0.1"
          spinner-small
          class="d-inline"
          spinner-variant="warning"
          :show="exporter.excel.exporting"
          style="margin-bottom: 1.1px;"
        >
          <json-excel
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :class="[
              'btn btn-success', {
                'btn-outline-success disabled': exporter.excel.exporting || !isValidForExport
              }
            ]"
            :fetch="excelProvider"
            :before-generate="onStartExcelExport"
            :before-finish="onFinishExcelExport"
            :name="excelFileName"
            :fields="exporter.excel.fields"
            :escape-csv="exporter.excel.escape"
            :type="exporter.excel.type"
            :header="exporter.excel.header"
            :footer="exporter.excel.footer"
            worksheet="Received Purchase Orders"
          >
            {{ exporter.excel.exporting ? 'Exporting' : 'Export As Excel' }}
          </json-excel>
        </b-overlay>
      </b-col>

    </b-row>

    <!-- Table -->
    <b-row
      v-if="Number(tabIndex) === 1"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableHistories"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :items="tableProvider"
          :busy="tableHistories.busy"
          :filter="tableHistories.filter"
          :fields="tableHistories.fields"
          :stacked="tableHistories.stacked"
          :sort-by.sync="tableHistories.sortBy"
          :sort-desc.sync="tableHistories.sortDesc"
          :sort-direction="tableHistories.sortDirection"
          :filter-included-fields="tableHistories.filterOn"
          :current-page="tableHistories.currentPage"
          :per-page="tableHistories.perPage"
        >
          <template #cell(index)="{ index }">
            {{ tableRowNumber(tableHistories, index) }}
          </template>

          <template #row-details="row">
            <div class="p-2 bg-white">
              <b-table
                small
                hover
                bordered
                responsive
                show-empty
                class="col-md-6 col-12"
                :stacked="tableHistories.stacked"
                :items="row.item.approvable.transfer_details"
                :fields="[
                  { key: 'index', label: '#', class: 'text-center' },
                  { key: 'product_name', label: 'Product Name', class: 'col-md-6 col-12' },
                  { key: 'quantity', label: 'Requested Quantity', class: 'text-center min-w-100px' },
                  { key: 'posting_quantity', label: 'Receiving Quantity', class: 'text-center min-w-100px', formatter: approvedToPostingQuantity }
                ]"
              >
                <template #cell(index)="{ index }">
                  <div style="min-width: 25px;">
                    {{ tableRowNumber(tableHistories, index) }}
                  </div>
                </template>

                <template #cell(quantity)="{ value }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      class="font-weight-bolder"
                      v-text="numberFormatter(value, '0,0')"
                    />
                  </div>
                </template>

                <template #cell(posting_quantity)="{ value, item }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      :class="['font-weight-bolder', {
                        'text-danger': Number(value) <= 0,
                        'text-warning': Number(value) >= 1 && Number(item.quantity) !== Number(value),
                        'text-success': Number(item.quantity) === Number(value)
                      }]"
                      v-text="numberFormatter(value, '0,0')"
                    />
                  </div>
                </template>

              </b-table>
            </div>
          </template>

          <template #cell(action)="{ item, toggleDetails, detailsShowing }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                variant="primary"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                :disabled="tableHistories.busy"
                @click.prevent="show(item)"
              >
                View
              </b-button>
              <b-button
                size="sm"
                :variant="detailsShowing ? 'danger' : 'warning' "
                :disabled="tableHistories.busy"
                @click.prevent="toggleDetails"
              >
                {{ detailsShowing ? 'Hide' : 'Show' }} Products
              </b-button>
            </div>
          </template>

          <template #cell(approvable.purchase_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.transfer_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.status)="{ value }">
            <b
              :class="[
                'min-w-80px',
                statusByVariant(value, 'text')
              ]"
              v-text="value"
            />
          </template>

          <template #cell(status)="{ value }">
            <b
              :class="[
                'min-w-80px',
                statusByVariant(value, 'text')
              ]"
              v-text="value"
            />
          </template>

          <template #cell()="{ value }">
            <div
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableHistories.perPage"
          :options="tableHistories.pageOptions"
          :disabled="tableHistories.busy"
          class="w-100 w-md-25"
          size="sm"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableHistories.currentPage"
          :total-rows="tableHistories.totalRows"
          :per-page="tableHistories.perPage"
          :disabled="tableHistories.busy"
          aria-controls="table"
          prev-text="Prev"
          next-text="Next"
          first-number
          last-number
          pills
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import Ripple from 'vue-ripple-directive'
import { SUserReceiving } from '@/services'

export default {

  name: 'UserReceivingHistory',

  directives: {
    Ripple
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    tabIndex: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      exporter: {
        excel: {
          exporting: false,
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            'Date Received': {
              field: 'date_received',
              callback: dateReceived => `="${this.dateFormatter(dateReceived)}"`
            },
            'Product Code': {
              field: 'product_code'
            },
            'Product Name': {
              field: 'product_name'
            },
            'IT Number': {
              field: 'transfer_number'
            },
            Quantity: {
              field: 'quantity'
            }
          },
          footer: []
        }
      },
      tableHistories: {
        busy: false,
        filter: {
          search: null,
          date_from: null,
          date_to: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 13, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'approvable.created_at', label: 'Requested At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.status', label: 'Request Status', class: 'text-center' },
          { mobile: 3, key: 'approvable.purchase_number', label: 'PO Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 4, key: 'approvable.transfer_number', label: 'Transfer Number', class: 'text-center min-w-150px', sortable: true },
          { mobile: 5, key: 'approvable.origin_name', label: 'Delivery From', class: 'min-w-200px' },
          { mobile: 6, key: 'approvable.schedule_details.driver_name', label: 'Delivered By', class: 'min-w-200px' },
          { mobile: 7, key: 'approvable.group_name', label: 'Group', class: 'min-w-200px' },
          { mobile: 8, key: 'approvable.transfer_details.length', label: 'Products', class: 'text-center' },
          { mobile: 9, key: 'approvable.delivery_details.customer', label: 'Customer', class: 'min-w-200px' },
          { mobile: 10, key: 'approvable.customer_direct', label: 'D2C', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 11, key: 'status', label: 'Receiving Status', class: 'text-center' },
          { mobile: 12, key: 'status_at', label: 'Received At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    excelFileName () {
      const dateFrom = this.dateShortFormatter(this.tableHistories.filter.date_from)
      const dateTo = this.dateShortFormatter(this.tableHistories.filter.date_to)
      return `Received Purchase Orders - ${dateFrom} to ${dateTo}`
    },

    isValidForExport () {
      return ![
        this.tableHistories.filter.date_from,
        this.tableHistories.filter.date_to
      ].includes(null)
    }
  },

  methods: {

    generateExcelRows (details, key) {
      const tdProps = ['product_code', 'product_name'].includes(key) ? '' : 'style="text-align: center;"'
      return `<table>${
        details.map(
          detail => (`<tr><td ${tdProps}>${detail[key]}</td></tr>`)
        ).join('')
      }</table>`
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableHistories.busy = true
      return await SUserReceiving.histories({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_date_from: filter.date_from,
        filter_date_to: filter.date_to
      }).then(({ data }) => {
        this.tableHistories.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableHistories.totalRows = 0
        return []
      }).finally(() => {
        this.tableHistories.busy = false
      })
    },

    tableRefresh () {
      this.$refs.tableHistories.refresh()
    },

    show (stockRequest) {
      this.$emit('show', stockRequest, false)
    },

    async excelProvider () {
      if (this.exporter.busy || [this.tableHistories.filter.date_from, this.tableHistories.filter.date_to].includes(null)) {
        return null
      }
      this.exporter.busy = this.exporter.excel.exporting = true
      return await SUserReceiving.exports({
        filter_text: this.tableHistories.filter.search,
        filter_date_from: this.tableHistories.filter.date_from,
        filter_date_to: this.tableHistories.filter.date_to
      }).then(({ data: { items } }) => {
        if (items.length <= 0) {
          this.swalInvalid(
            'No available data to be Exported'
          )
        }

        const reportFormat = []

        items.forEach(approver => {
          approver.approvable.transfer_details.forEach(
            detail => {
              reportFormat.push({
                transfer_number: approver.approvable.transfer_number,
                date_received: approver.status_at,
                product_code: detail.product_code,
                product_name: detail.product_name,
                quantity: detail.posting_quantity
              })
            }
          )
        })

        return reportFormat
      }).catch(() => {
        return []
      }).finally(() => {
        this.exporter.busy = this.exporter.excel.exporting = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy || [this.tableHistories.filter.date_from, this.tableHistories.filter.date_to].includes(null)) {
        return null
      }

      this.exporter.excel.header = []
      this.exporter.excel.footer = []
    },

    onFinishExcelExport () {
    }
  }
}
</script>
