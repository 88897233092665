<template>
  <ValidationProvider
    ref="product"
    #default="{ errors }"
    :vid="vProduct"
    name="product"
    rules="required"
  >
    <b-form-group
      :label-for="vProduct"
      label-class="font-weight-bolder"
      class="mb-0"
    >
      <v-select
        v-model="item.product"
        placeholder="search product here"
        label="product_name"
        clearable
        append-to-body
        select-on-tab
        :tabindex="(index + 1)"
        :input-id="vProduct"
        :options="(options)"
        :loading="(fetching)"
        :disabled="(fetching)"
        :map-keydown="mapKeyDown"
        :calculate-position="calculateDropPosition"
        :class="['border-0', { 'is-invalid': errors.length > 0 }]"
        @option:selected="selected"
      >
        <template #no-options="">
          no available product
        </template>
      </v-select>
      <div
        v-if="(errors.length > 0)"
        class="invalid-feedback"
        v-text="errors[0]"
      />
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
export default {
  name: 'Product',

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    index: {
      type: Number,
      default: () => 0,
      required: true
    },
    fetching: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Array,
      default: () => [],
      required: true
    },
    item: {
      type: Object,
      default: () => ({
        product: null
      }),
      required: true
    }
  },

  computed: {
    vProduct () {
      return `details.${this.index}.product`
    }
  },

  methods: {
    mapKeyDown (map, context) {
      return {
        ...map,
        // 8: ({ target }) => {
        //   if (target.value === null || target.value === '') {
        //     this.removed()
        //   }
        //   return context.maybeDeleteValue()
        // },
        9: () => {
          if (context.open) {
            return context.onTab()
          }

          return null
        },
        13: evt => {
          evt.preventDefault()
          return context.typeAheadSelect()
        }
      }
    },

    // removed () {
    //   this.$emit('removed', this.item, this.index, true)
    // },

    selected (product) {
      this.$emit('selected', product, this.index)
    }
  }
}
</script>
