<template>
  <b-container
    fluid
  >
    <div class="d-flex flex-column flex-md-row justify-content-md-between mb-0 pb-0">
      <div
        class="order-2 order-md-1"
      >
        <b-button
          size="sm"
          variant="success"
          @click.prevent="createRequest"
        >
          Create Request
        </b-button>
      </div>
      <div
        class="order-1 order-md-2 align-self-end mb-3 mb-md-0"
      >
        <b-breadcrumb
          v-if="$route.meta.breadcrumb"
          :items="$route.meta.breadcrumb || []"
        />
      </div>
    </div>

    <b-tabs
      v-model="currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
      class="mt-3"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-text', currentTabIndex === 0)" /> Pendings
        </template>
        <RequestPending
          ref="srPending"
          :tab-index="currentTabIndex"
          @show="showRequestPreview"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-3', currentTabIndex === 1)" /> Drafts
        </template>
        <RequestDraft
          ref="srDraft"
          :tab-index="currentTabIndex"
          @show="showRequest"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-history', currentTabIndex === 2)" /> Histories
        </template>
        <RequestHistory
          ref="srHistory"
          :tab-index="currentTabIndex"
          @show="showRequestPreview"
          @edit="showRequest"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      id="modalRequest"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      content-class="full-height"
      modal-class="full-height"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i
                :class="['ri-stack-overflow-line h4', {
                  'text-danger fat fa-shake': vTabStockRequest() || vTabProducts()
                }]"
              />
              <b
                :class="['is-tab-active ml-1', {
                  'text-danger': vTabStockRequest() || vTabProducts()
                }]"
              >
                Products
              </b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              md="4"
              class="px-1 px-md-2"
            >
              <ValidationObserver
                ref="formStockRequest"
              >
                <form
                  role="form"
                  novalidate
                  @submit.prevent
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="px-1 px-md-2"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        vid="headers.origin"
                        name="location"
                        rules="required"
                      >
                        <b-form-group
                          label="Request To"
                          label-for="fOrigin"
                          label-class="font-weight-bolder"
                          class="mb-2"
                        >
                          <v-select
                            v-model="selected.origin"
                            input-id="fOrigin"
                            label="location_name"
                            placeholder="search location here"
                            :options="options.origins"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :disabled="fetching.origins"
                            :loading="fetching.origins"
                          >
                            <template #no-options="">
                              no available location
                            </template>
                          </v-select>
                          <div
                            v-if="(errors.length > 0)"
                            class="invalid-feedback"
                            v-text="errors[0]"
                          />
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </b-col>

            <b-col
              cols="12"
              class="px-0 px-md-1"
            >
              <ValidationObserver
                ref="formProducts"
              >
                <Products
                  ref="componentProducts"
                  :details="stockRequest.details"
                  :options="options.products"
                />
              </ValidationObserver>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i
                :class="['ri-todo-line h4', {
                  'text-danger fat fa-shake': vTabDeliveryDetails()
                }]"
              />
              <b
                :class="['is-tab-active ml-1', {
                  'text-danger': vTabDeliveryDetails()
                }]"
              >
                Delivery Details
              </b>
            </div>
          </template>

          <ValidationObserver
            ref="formCustomer"
          >
            <b-row class="mx-0 mt-1 mb-0">
              <b-col
                cols="12"
                class="px-1 px-md-2"
              >
                <b-row>
                  <b-col
                    cols="12"
                    class="px-1 px-md-2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="headers.customer_direct"
                      name="customer direct"
                    >
                      <b-form-group
                        class="mb-1"
                        label-for="fCustomerDirect"
                      >
                        <b-form-checkbox
                          id="fCustomerDirect"
                          v-model="stockRequest.delivery_details.customer_direct"
                          switch
                          value="1"
                          name="headers.customer_direct"
                          unchecked-value="0"
                          :disabled="busy"
                        >
                          <strong>Direct Delivery to Customer?</strong>
                        </b-form-checkbox>

                        <div
                          v-if="(errors.length > 0)"
                          class="invalid-feedback"
                          v-text="errors[0]"
                        />
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                    class="px-1 px-md-2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="delivery_details.mobile_number"
                      name="mobile number"
                      rules="required_if:headers.customer_direct,1|max:255"
                    >
                      <b-form-group
                        label="Mobile Number"
                        label-for="fMobileNumber"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fMobileNumber"
                          v-model="stockRequest.delivery_details.mobile_number"
                          type="text"
                          max-length="255"
                          placeholder="enter mobile number here"
                          :state="(errors.length > 0 ? false : null)"
                          :disabled="(busy || Number(stockRequest.delivery_details.customer_direct) === 0)"
                        />

                        <div
                          v-if="(errors.length > 0)"
                          class="invalid-feedback"
                          v-text="errors[0]"
                        />
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="9"
                    class="px-1 px-md-2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="delivery_details.customer"
                      name="customer name"
                      rules="required_if:headers.customer_direct,1|max:255"
                    >
                      <b-form-group
                        label="Customer Name"
                        label-for="fCustomerName"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fCustomerName"
                          v-model="stockRequest.delivery_details.customer"
                          type="text"
                          max-length="255"
                          placeholder="enter customer name here"
                          :state="(errors.length > 0 ? false : null)"
                          :disabled="(busy || Number(stockRequest.delivery_details.customer_direct) === 0)"
                        />

                        <div
                          v-if="(errors.length > 0)"
                          class="invalid-feedback"
                          v-text="errors[0]"
                        />
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    class="px-1 px-md-2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      vid="headers.address"
                      name="address"
                      rules="required_if:headers.customer_direct,1|max:255"
                    >
                      <b-form-group
                        class="mb-2"
                      >
                        <label
                          for="fCustomerAddress"
                          class="d-flex justify-content-between"
                        >
                          <b>Customer Address</b>
                          <span>
                            {{ stockRequest.delivery_details.address ? stockRequest.delivery_details.address.length : 0 }} / 255
                          </span>
                        </label>
                        <b-textarea
                          id="fCustomerAddress"
                          v-model="stockRequest.delivery_details.address"
                          rows="4"
                          max-rows="6"
                          maxlength="255"
                          placeholder="enter customer address here"
                          :state="(errors.length > 0 ? false : null)"
                          :disabled="(busy || Number(stockRequest.delivery_details.customer_direct) === 0)"
                        />

                        <div
                          v-if="(errors.length > 0)"
                          class="invalid-feedback"
                          v-text="errors[0]"
                        />
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                </b-row>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="pb-0 d-flex justify-content-between mb-0 px-0 flex-grow-1">
          <div>
            <b-button
              variant="secondary"
              @click.prevent="validateRequestAsDraft"
            >
              {{ SubmitRequestAsDraftButton }}
            </b-button>
          </div>
          <div>
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validateRequest"
            >
              Submit <span class="d-none d-md-inline-block">Request</span>
            </b-button>
            <b-button
              variant="outline-dark"
              @click.prevent="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modalRequestPreview"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      content-class="full-height"
      modal-class="full-height"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-stack-overflow-line h4" />
              <b class="is-tab-active">Products</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="PO Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.purchase_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Requested To"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.origin_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Group"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.group_name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Remarks"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.remarks || 'no remarks'"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="px-0 px-md-1"
            >
              <b-table
                ref="tableProducts"
                small
                hover
                striped
                bordered
                responsive
                show-empty
                class="mb-0"
                :stacked="tableProducts.stacked"
                :items="stockRequest.details"
                :fields="tableProducts.fields"
                :filter="tableProducts.filter"
                :filter-included-fields="tableProducts.filterOn"
                :busy="tableProducts.busy"
              >
                <template #cell(index)="{ index }">
                  <div
                    class="text-md-nowrap d-flex justify-content-center align-items-centermin-h-35px"
                    v-text="tableRowNumber(tableProducts, index)"
                  />
                </template>

                <template #cell(product_name)="{ value }">
                  <div class="text-md-nowrap d-flex align-items-center min-h-35px px-1">
                    <span
                      v-text="value"
                    />
                  </div>
                </template>

                <template #cell(quantity)="{ value }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      class="font-weight-bolder"
                      v-text="numberFormatter(value, '0,0')"
                    />
                  </div>
                </template>

                <template #cell(approved_quantity)="{ value, item }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      :class="['font-weight-bolder', {
                        'text-danger': Number(value) <= 0,
                        'text-warning': Number(value) >= 1 && Number(item.quantity) !== Number(value),
                        'text-success': Number(item.quantity) === Number(value)
                      }]"
                      v-text="numberFormatter(value, '0,0')"
                    />
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center py-5">
                    <b-icon
                      icon="stopwatch"
                      font-scale="5"
                      animation="cylon"
                    />
                    <p class="h3 py-2">
                      <strong>Loading . . .</strong>
                    </p>
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-todo-line h4" />
              <b class="is-tab-active">Delivery Details</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label-for="fCustomerDirect"
                    class="mb-1"
                  >
                    <b-form-checkbox
                      id="fCustomerDirect"
                      v-model="stockRequest.delivery_details.customer_direct"
                      switch
                      value="1"
                      name="customer_direct"
                      unchecked-value="0"
                      disabled
                    >
                      <strong>Direct Deliver to Customer?</strong>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvCustomerMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerMobile"
                      v-model="stockRequest.delivery_details.mobile_number"
                      type="text"
                      placeholder="no customer mobile"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Name"
                    label-for="fvCustomerName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerName"
                      v-model="stockRequest.delivery_details.customer"
                      type="text"
                      placeholder="no customer name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Address"
                    label-for="fvCustomerAddress"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-textarea
                      id="fvCustomerAddress"
                      v-model="stockRequest.delivery_details.address"
                      rows="4"
                      max-rows="6"
                      maxlength="50"
                      placeholder="no customer address"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-calendar-line h4" />
              <b class="is-tab-active ml-1">Delivery Schedule</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Delivery Date"
                    label-for="fvScheduleDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleDate"
                      v-model="stockRequest.schedule_details.date"
                      type="date"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="5"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Driver"
                    label-for="fvScheduleDriver"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleDriver"
                      v-model="stockRequest.schedule_details.driver_name"
                      type="text"
                      maxlength="50"
                      placeholder="no driver"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvScheduleMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleMobile"
                      v-model="stockRequest.schedule_details.mobile_number"
                      type="text"
                      maxlength="50"
                      placeholder="no mobile number"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                />

                <b-col
                  cols="12"
                  md="8"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Helper"
                    label-for="fvScheduleHelper"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleHelper"
                      v-model="stockRequest.schedule_details.helper"
                      type="text"
                      maxlength="50"
                      placeholder="no helper"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Vehicle Plate"
                    label-for="fvScheduleVehiclePlate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleVehiclePlate"
                      v-model="stockRequest.schedule_details.vehicle_plate"
                      type="text"
                      maxlength="25"
                      placeholder="no vehicle plate"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="8"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Vehicle Name"
                    label-for="fvScheduleVehicleName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleVehicleName"
                      v-model="stockRequest.schedule_details.vehicle_name"
                      type="text"
                      maxlength="50"
                      placeholder="no helper"
                      disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-history-line h4" />
              <b class="is-tab-active ml-1">Tracking</b>
            </div>
          </template>

          <Timeline
            :stock-request="stockRequest"
          >
            <template #approvals="request">
              <Signatory
                :stock-request="request"
                :approver="requesterSignatory"
              />
              <Signatory
                v-if="['Canceled'].includes(canceledSignatory.status)"
                :stock-request="request"
                :approver="canceledSignatory"
              />
              <Signatory
                v-for="(approver, key) in request.approvals"
                :key="key"
                :stock-request="request"
                :approver="approver"
              />
            </template>
          </Timeline>
        </b-tab>

      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="col-12 pb-0 d-flex justify-content-end mb-0 px-0">
          <div>
            <b-button
              variant="outline-dark"
              @click.prevent="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
// import FUSE from 'fuse.js'
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SSharedList } from '@/services'
import { mapGetters } from 'vuex'

import RequestPending from '@/views/user/requests/Pending'
import RequestDraft from '@/views/user/requests/Draft'
import RequestHistory from '@/views/user/requests/History'
import Products from '@/views/user/requests/products/Base'
import Timeline from '@/components/custom/timeline/Base'
import Signatory from '@/components/custom/timeline/Signatory'

export default {

  name: 'UserRequestPending',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Purchase Orders'
  }),

  components: {
    RequestPending,
    RequestDraft,
    RequestHistory,
    Products,
    Timeline,
    Signatory
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  data () {
    return {
      busy: false,
      editing: false,
      currentTabIndex: this.getCurrentTabIndexByURL(),
      fetching: {
        origins: false,
        products: false
      },
      options: {
        origins: [],
        products: []
      },
      selected: {
        origin: null
      },
      stockRequest: {
        id: null,
        stage: 0,
        references: {
          document_number: null,
          transfer_number: null,
          sap_doc_entry: null,
          purchase_number: null
        },
        headers: {
          group: null,
          group_name: null,
          group_code: null,
          origin: null,
          origin_name: null,
          origin_code: null
        },
        delivery_details: {
          customer_direct: 0,
          customer: null,
          mobile_number: null,
          address: null,
          remarks: null
        },
        schedule_details: {
          date: null,
          vehicle: null,
          vehicle_plate: null,
          vehicle_name: null,
          driver: null,
          driver_name: null,
          mobile_number: null,
          helper: null
        },
        details: [],
        approvals: [],
        created_at: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      canceledSignatory: {
        stage: 1,
        approver_label: 'Requester',
        approver_action: 'Cancelation',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      tableProducts: {
        selected: {
          details: [],
          allSelected: false,
          indeterminate: false
        },
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'product_name', class: 'min-w-200px' },
          { mobile: 2, key: 'quantity', class: ['text-center', { 'col-2': !this.isMobile() }] },
          { mobile: 3, key: 'approved_quantity', label: 'Approved', class: ['text-center', { 'col-2': !this.isMobile() }] }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {

    ...mapGetters({
      user: 'auth/user'
    }),

    SubmitRequestAsDraftButton () {
      if (this.editing) {
        return 'Update Draft'
      }

      return 'Save Draft'
    }
  },

  watch: {

    'selected.origin' (origin) {
      this.stockRequest.headers.origin = origin?.id || null
      this.stockRequest.headers.origin_code = origin?.location_code || null
      this.stockRequest.headers.origin_name = origin?.location_name || null
    },

    'stockRequest.delivery_details.customer_direct' (customerDirect) {
      if (Number(customerDirect) === 0) {
        this.stockRequest.delivery_details.customer = null
        this.stockRequest.delivery_details.mobile_number = null
        this.stockRequest.delivery_details.address = null
      }
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    vTabStockRequest () {
      return this.$refs.formStockRequest && Object.keys(this.$refs.formStockRequest.errors).find(
        key => this.$refs.formStockRequest.errors[key]?.length > 0
      )
    },

    vTabProducts () {
      return this.$refs.formProducts && Object.keys(this.$refs.formProducts.errors).find(
        key => this.$refs.formProducts.errors[key]?.length > 0
      )
    },

    vTabDeliveryDetails () {
      return this.$refs.formCustomer && Object.keys(this.$refs.formCustomer.errors).find(
        key => this.$refs.formCustomer.errors[key]?.length > 0
      )
    },

    getCurrentTabIndexByURL () {
      switch (this.$route.params.requester) {
        case 'histories' : {
          return 2
        }
        case 'drafts' : {
          return 1
        }
        default: {
          return 0
        }
      }
    },

    async getLocations () {
      this.fetching.origins = true
      return await SSharedList.getLocations({
        allow_out: 1
      }).then(({ data }) => {
        this.options.origins = data.filter(
          origin => Number(origin.allow_out) === 1 && (
            Number(this.user?.location) !== Number(origin.id)
          )
        )
      }).catch(() => {
        this.options.origins = []
      }).finally(() => {
        this.fetching.origins = false
      })
    },

    getProducts () {
      this.fetching.products = true
      return SSharedList.getProducts().then(({ data }) => {
        this.options.products = data.map(({ id, category, product_code, product_name }) => ({
          id: id,
          category_id: category.id,
          category_code: category.category_code,
          category_name: category.category_name,
          group_code: category.group?.group_code || null,
          group_name: category.group?.group_name || null,
          product_code: product_code,
          product_name: product_name
        }))
      }).catch(() => {
        this.options.products = []
      }).finally(() => {
        this.fetching.products = false
      })
    },

    showRequest (stockRequest) {
      this.editing = true

      this.selected.origin = null

      const { headers, delivery_details, details } = stockRequest

      this.getProducts().finally(() => {
        this.stockRequest = Object.assign(this.stockRequest, {
          id: stockRequest.id,
          stage: 0,
          references: {
            document_number: null,
            transfer_number: null,
            sap_doc_entry: null,
            purchase_number: null
          },
          headers: {
            group: null,
            group_code: null,
            group_name: null
          },
          delivery_details: {
            customer_direct: Number(headers.customer_direct),
            customer: delivery_details.customer,
            mobile_number: delivery_details.mobile_number,
            address: delivery_details.address
          },
          schedule_details: {
            date: null,
            vehicle: null,
            vehicle_plate: null,
            vehicle_name: null,
            driver: null,
            driver_name: null,
            mobile_number: null,
            helper: null
          },
          details: details.map(
            detail => Object.assign(detail, {
              product: QUERY.find(this.options.products, {
                product_code: detail.product_code
              })
            })
          ),
          approvals: [],
          remarks: headers.remarks,
          created_at: null
        })

        this.getLocations().finally(() => {
          this.selected.origin = QUERY.find(this.options.origins, {
            id: headers.origin
          })
        })

        this.$refs.srDraft.loaded()
        this.$bvModal.show('modalRequest')
      })
    },

    showRequestPreview (stockRequest) {
      const { delivery_details, schedule_details } = stockRequest

      this.stockRequest = Object.assign(this.stockRequest, {
        id: stockRequest.id,
        stage: stockRequest.stage,
        references: {
          document_number: stockRequest.document_number,
          transfer_number: stockRequest.transfer_number,
          sap_doc_entry: stockRequest.sap_doc_entry,
          purchase_number: stockRequest.purchase_number
        },
        headers: {
          origin: stockRequest.origin_id,
          origin_code: stockRequest.origin_code,
          origin_name: stockRequest.origin_name,
          group: stockRequest.group_id,
          group_code: stockRequest.group_code,
          group_name: stockRequest.group_name
        },
        delivery_details: {
          customer_direct: Number(stockRequest.customer_direct),
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        schedule_details: {
          date: schedule_details?.date || null,
          vehicle: schedule_details?.vehicle || null,
          vehicle_plate: schedule_details?.vehicle_plate || null,
          vehicle_name: schedule_details?.vehicle_name || null,
          driver: schedule_details?.driver || null,
          driver_name: schedule_details?.driver_name || null,
          mobile_number: schedule_details?.mobile_number || null,
          helper: schedule_details?.helper || null
        },
        details: stockRequest.transfer_details,
        approvals: stockRequest.approvals,
        remarks: stockRequest.remarks,
        created_at: stockRequest.created_at
      })

      this.requesterSignatory = Object.assign(this.requesterSignatory, {
        approver_name: stockRequest.destination_name,
        status_at: stockRequest.created_at
      })

      this.canceledSignatory = Object.assign(this.canceledSignatory, {
        approver_name: stockRequest.destination_name,
        remarks: stockRequest.canceled_remarks,
        status: stockRequest.status,
        status_at: stockRequest.canceled_at
      })

      this.$bvModal.show('modalRequestPreview')
    },

    createRequest () {
      this.editing = false

      this.selected.origin = null

      this.stockRequest = Object.assign(this.stockRequest, {
        id: null,
        stage: 0,
        references: {
          document_number: null,
          transfer_number: null,
          sap_doc_entry: null,
          purchase_number: null
        },
        headers: {
          customer_direct: 0,
          group: null,
          group_name: null,
          group_code: null,
          remarks: null
        },
        delivery_details: {
          customer_direct: 0,
          customer: null,
          mobile_number: null,
          address: null
        },
        schedule_details: {
          date: null,
          vehicle: null,
          vehicle_plate: null,
          vehicle_name: null,
          driver: null,
          driver_name: null,
          mobile_number: null,
          helper: null
        },
        details: Array.from({ length: 5 }).map(
          () => ({
            product: null,
            quantity: null
          })
        ),
        remarks: null
      })

      this.getLocations().finally(() => {
        this.getProducts()
      })

      this.$bvModal.show('modalRequest')
    },

    validateRequest () {
      this.$refs.formStockRequest.validate().then(
        validStockRequest => {
          this.$refs.formProducts.validate().then(
            validProduct => {
              this.$refs.formCustomer.validate().then(
                validCustomer => {
                  if (!validStockRequest || !validProduct || !validCustomer) {
                    return this.swalInvalid()
                  }

                  this.swalConfirmWithRemarks({
                    html: () => {
                      if (this.editing) {
                        return 'Submit Draft as Purchase Order?'
                      }

                      return 'Submit Purchase Order?'
                    },
                    remarksRequired: true,
                    remarksMaxLength: 220,
                    preConfirm: remarks => {
                      if (this.editing) {
                        return this.updateDraft(0, remarks)
                      }

                      return this.submitRequest(remarks)
                    },
                    confirmButtonText: 'Submit'
                  })
                }
              )
            })
        })
    },

    validateRequestAsDraft () {
      this.swalConfirm({
        html: () => {
          if (this.editing) {
            return 'Update changes on this Draft?'
          }

          return 'Save Purchase Order as Draft?'
        },
        preConfirm: () => {
          if (this.editing) {
            return this.updateDraft()
          }

          return this.submitDraft()
        },
        confirmButtonText: this.editing ? 'Update' : 'Save'
      })
    },

    setProductErrors (errors) {
      const mappedErrors = {}

      Object.keys(errors).forEach(error => {
        const splitted = error.split('.')
        if (splitted.length > 0) {
          const productFields = [
            'category_code',
            'category_name',
            'product_code',
            'product_name',
            'group_code',
            'group_name'
          ]
          if (splitted[0] === 'details' && productFields.includes(splitted[splitted.length - 1])) {
            mappedErrors[`details.${splitted[1]}.product`] = errors[error]
          } else {
            mappedErrors[error] = errors[error]
          }
        }
      })

      this.$refs.formProducts.setErrors(mappedErrors)
    },

    submitDraft () {
      const { id, headers, delivery_details } = this.stockRequest
      return this.$refs.srDraft.create({
        id: id,
        headers: {
          origin: headers.origin,
          origin_name: headers.origin_name,
          origin_code: headers.origin_code,
          customer_direct: delivery_details.customer_direct,
          remarks: null
        },
        delivery_details: {
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        details: this.$refs.componentProducts.getFinalProducts(),
        is_draft: 1,
        active: 1
      }).then(() => {
        this.currentTabIndex = 1
      }).catch(
        errors => {
          this.setProductErrors(errors)
          this.$refs.formStockRequest.setErrors(errors)
        }
      )
    },

    updateDraft (isDraft = 1, remarks = null) {
      const { id, headers, delivery_details } = this.stockRequest
      return this.$refs.srDraft.update({
        id: id,
        headers: {
          origin: headers.origin,
          origin_name: headers.origin_name,
          origin_code: headers.origin_code,
          customer_direct: delivery_details.customer_direct,
          remarks: remarks
        },
        delivery_details: {
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        details: this.$refs.componentProducts.getFinalProducts(),
        is_draft: isDraft,
        active: 1
      }).then(() => {
        this.currentTabIndex = Number(isDraft)
      }).catch(
        errors => {
          this.setProductErrors(errors)
          this.$refs.formStockRequest.setErrors(errors)
        }
      )
    },

    submitRequest (remarks = null) {
      const { id, headers, delivery_details } = this.stockRequest
      return this.$refs.srPending.create({
        id: id,
        origin: headers.origin,
        customer_direct: delivery_details.customer_direct,
        remarks: remarks,
        delivery_details: {
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        details: this.$refs.componentProducts.getFinalProducts(),
        active: 1
      }).then(() => {
        this.currentTabIndex = 0
      }).catch(
        errors => {
          this.$refs.formStockRequest.setErrors({
            'headers.origin': errors?.origin || [],
            'headers.category': errors?.category || []
          })

          this.setProductErrors(errors)
          this.$refs.formCustomer.setErrors(errors)
        }
      )
    }
  }
}
</script>
