<template>
  <b-container
    class="mt-2"
    fluid
  >
    <!-- Filters -->
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="px-1"
      >
        <b-form-group
          label="Search"
          label-for="filterHistorySearch"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistorySearch"
            v-model="tableHistories.filter.search"
            placeholder="search here"
            autocomplete="off"
            debounce="1000"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Status"
          label-for="filterHistoryStatus"
          label-class="font-weight-bolder"
        >
          <b-form-select
            id="filterHistoryStatus"
            v-model="tableHistories.filter.status"
            :options="tableHistories.filterOptions.statuses"
            debounce="1000"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- select status here --
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Processed From"
          label-for="filterHistoryDateFrom"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistoryDateFrom"
            v-model="tableHistories.filter.date_from"
            :max="tableHistories.filter.date_to"
            debounce="1000"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="6"
        md="2"
        class="px-1"
        order="2"
      >
        <b-form-group
          label="Processed To"
          label-for="filterHistoryDateTo"
          label-class="font-weight-bolder"
        >
          <b-input
            id="filterHistoryDateTo"
            v-model="tableHistories.filter.date_to"
            :min="tableHistories.filter.date_from"
            debounce="1000"
            type="date"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <!-- Table -->
    <b-row
      v-if="Number(tabIndex) === 1"
      class="mt-1"
    >
      <b-col
        cols="12"
        class="px-1"
      >
        <b-table
          ref="tableHistories"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          :items="tableProvider"
          :busy="tableHistories.busy"
          :filter="tableHistories.filter"
          :fields="tableHistories.fields"
          :stacked="tableHistories.stacked"
          :sort-by.sync="tableHistories.sortBy"
          :sort-desc.sync="tableHistories.sortDesc"
          :sort-direction="tableHistories.sortDirection"
          :filter-included-fields="tableHistories.filterOn"
          :current-page="tableHistories.currentPage"
          :per-page="tableHistories.perPage"
        >
          <template #cell(index)="{ index }">
            {{ tableRowNumber(tableHistories, index) }}
          </template>

          <template #row-details="row">
            <div class="p-2 bg-white">
              <b-table
                small
                hover
                bordered
                responsive
                show-empty
                class="col-md-6 col-12"
                :stacked="tableHistories.stacked"
                :items="row.item.approvable.transfer_details"
                :fields="[
                  { key: 'index', label: '#', class: 'text-center' },
                  { key: 'product_name', label: 'Product Name', class: 'col-md-6 col-12' },
                  { key: 'quantity', label: 'Requested Quantity', class: 'text-center min-w-100px' },
                  { key: 'approved_quantity', label: 'Approved Quantity', class: 'text-center min-w-100px' },
                  { key: 'posting_quantity', label: 'Posted Quantity', class: 'text-center min-w-100px' }
                ]"
              >
                <template #cell(index)="{ index }">
                  <div style="min-width: 25px;">
                    {{ tableRowNumber(tableHistories, index) }}
                  </div>
                </template>

                <template #cell(quantity)="{ value }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      class="font-weight-bolder"
                      v-text="numberFormatter(value, '0,0')"
                    />
                  </div>
                </template>

                <template #cell(approved_quantity)="{ value }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      class="font-weight-bolder"
                      v-text="numberFormatter(value, '0,0')"
                    />
                  </div>
                </template>

                <template #cell(posting_quantity)="{ value, item }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      :class="['font-weight-bolder', {
                        'text-danger': Number(value) <= 0,
                        'text-warning': Number(value) >= 1 && Number(item.approved_quantity) !== Number(value),
                        'text-success': Number(item.approved_quantity) === Number(value)
                      }]"
                      v-text="numberFormatter(value, '0,0')"
                    />
                  </div>
                </template>
              </b-table>
            </div>
          </template>

          <template #cell(action)="{ item, toggleDetails, detailsShowing }">
            <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-md-start justify-content-center">
              <b-button
                size="sm"
                variant="primary"
                class="mr-0 mr-md-1 mb-1 mb-md-0"
                :disabled="tableHistories.busy"
                @click.prevent="show(item)"
              >
                View
              </b-button>
              <b-button
                size="sm"
                :variant="detailsShowing ? 'danger' : 'warning' "
                :disabled="tableHistories.busy"
                @click.prevent="toggleDetails"
              >
                {{ detailsShowing ? 'Hide' : 'Show' }} Products
              </b-button>
            </div>
          </template>

          <template #cell(approvable.purchase_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.document_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.sap_doc_entry)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.transfer_number)="{ value }">
            <b
              class="text-md-nowrap"
              v-text="value"
            />
          </template>

          <template #cell(approvable.status)="{ value }">
            <b
              :class="[
                'min-w-80px',
                statusByVariant(value, 'text')
              ]"
              v-text="value"
            />
          </template>

          <template #cell(status)="{ value }">
            <b
              :class="[
                'min-w-80px',
                statusByVariant(value, 'text')
              ]"
              v-text="value"
            />
          </template>

          <template #cell()="{ value }">
            <div class="text-md-nowrap">
              {{ value }}
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="mt-1 mb-2 px-1"
      >
        <b-select
          v-model="tableHistories.perPage"
          :options="tableHistories.pageOptions"
          :disabled="tableHistories.busy"
          class="w-100 w-md-25"
          size="sm"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
      >
        <b-pagination
          v-model="tableHistories.currentPage"
          :total-rows="tableHistories.totalRows"
          :per-page="tableHistories.perPage"
          :disabled="tableHistories.busy"
          aria-controls="table"
          prev-text="Prev"
          next-text="Next"
          first-number
          last-number
          pills
        />
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { SLMApproval } from '@/services'

export default {

  name: 'LogisticsManagerApprovalHistory',

  mixins: [
    MISC,
    FORMATTER
  ],

  props: {
    tabIndex: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      tableHistories: {
        busy: false,
        filter: {
          search: null,
          status: this.$route.params?.status || 'all',
          date_from: null,
          date_to: null
        },
        filterOptions: {
          statuses: [
            { value: 'all', text: 'All' },
            { value: 'approved', text: 'Approved' },
            { value: 'disapproved', text: 'Disapproved' }
          ]
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 15, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'approvable.created_at', label: 'Requested At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approvable.status', label: 'Request Status', class: 'text-center' },
          { mobile: 3, key: 'approvable.purchase_number', label: 'PO Number', class: 'text-center min-w-100px', sortable: true },
          { mobile: 4, key: 'approvable.document_number', label: 'Document Number', class: 'text-center min-w-100px', sortable: true },
          { mobile: 5, key: 'approvable.transfer_number', label: 'Transfer Number', class: 'text-center min-w-100px', sortable: true },
          { mobile: 6, key: 'approvable.sap_doc_entry', label: 'SAP Entry', class: 'text-center min-w-100px', sortable: true },
          { mobile: 7, key: 'approvable.destination_name', label: 'Requested By', class: 'min-w-200px' },
          { mobile: 8, key: 'approvable.origin_name', label: 'Requested To', class: 'min-w-200px' },
          { mobile: 9, key: 'approvable.group_name', label: 'Group', class: 'min-w-200px' },
          { mobile: 10, key: 'approvable.transfer_details.length', label: 'Products', class: 'text-center' },
          { mobile: 11, key: 'approvable.delivery_details.customer', label: 'Customer', class: 'min-w-200px' },
          { mobile: 12, key: 'approvable.customer_direct', label: 'D2C', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 13, key: 'status', label: 'Approval Status', class: 'text-center' },
          { mobile: 14, key: 'status_at', label: 'Approval At', class: 'min-w-175px', formatter: this.dateTimeShortFormatter }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableHistories.busy = true
      return await SLMApproval.histories({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_status: filter.status,
        filter_date_from: filter.date_from,
        filter_date_to: filter.date_to
      }).then(({ data }) => {
        this.tableHistories.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableHistories.totalRows = 0
        return []
      }).finally(() => {
        this.tableHistories.busy = false
      })
    },

    tableRefresh () {
      this.$refs.tableHistories.refresh()
    },

    show (stockRequest) {
      this.$emit('show', stockRequest, false)
    }
  }
}
</script>
